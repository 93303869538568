import styled from "styled-components";
import { P, Icon, GridRow, Small } from "notes";

export const StepBullets = ({ steps, vertical = false, ...props }) => {
  return (
    <Container vertical={vertical} {...props}>
      {steps.map(({ status, disabled, label, content, progress }, index) => {
        const active = status === "active";
        const complete = status === "complete";
        return (
          <>
            <Step
              key={index}
              active={active}
              complete={complete}
              vertical={vertical}
            >
              <AnimationContainer>
                {complete || active ? (
                  <Green badge name="FacebookVerified" />
                ) : (
                  <Disabled indicator name="ProgressActive" />
                )}
                {active && <Pulse />}
              </AnimationContainer>
              <StepLabel>{label}</StepLabel>
              {!complete && active && !!progress && (
                <Percentage>
                  {progress}
                  <Sign>%</Sign>
                </Percentage>
              )}
            </Step>
            {index !== steps.length - 1 && (
              <Description
                vertical={vertical}
                active={active}
                disabled={disabled}
              >
                {vertical ? (
                  <VLine active={complete} />
                ) : (
                  <HLine active={complete} />
                )}
                {!!content && vertical && <Small>{content}</Small>}
              </Description>
            )}
          </>
        );
      })}
    </Container>
  );
};

const Percentage = styled(GridRow)`
  color: ${(props) => props.theme.palette.gray.lighter};
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
  font-weight: 600;
`;

const Sign = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: 8px;
  font-weight: 600;
`;

const Description = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "row" : "column")};
  flex-grow: 1;
  justify-content: flex-start;
  position: relative;
  ${Small} {
    color: ${(props) =>
      !props.disabled
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    padding: 5px 0 0 8px;
    position: absolute;
    top: 0;
    left: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  margin: ${(props) => (props.vertical ? 0 : "0 36px")};
  justify-content: center;
`;

const VLine = styled.div`
  background: ${(props) =>
    props.active
      ? props.theme.palette.teal.primary
      : props.theme.palette.gray.lightest};
  display: block;
  margin: 8px 0 8px 7px;
  width: 2px;
  height: 36px;
`;

const HLine = styled(VLine)`
  margin: 7px 8px 0 8px;
  width: calc(100% - 16px);
  height: 2px;
`;

const StepLabel = styled(P)``;

const Step = styled(GridRow)`
  flex-direction: ${(props) => (props.vertical ? "row" : "column")};
  ${(props) => !props.vertical && `width: 16px;`};
  align-items: center;
  ${StepLabel} {
    color: ${(props) =>
      props.active || props.complete
        ? props.theme.palette.black
        : props.theme.palette.gray.lighter};
    font-size: 14px;
    font-weight: ${(props) => (props.active || props.complete ? 700 : 300)};
    line-height: 18px;
    margin: ${(props) => (!props.vertical ? `8px 0 0 0` : "0 0 0 8px")};
    white-space: nowrap;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${StepLabel} {
      font-size: 11px;
      line-height: 14px;
    }
  }
`;

const Green = styled(Icon)`
  path {
    fill: ${(props) => props.theme.palette.teal.primary};
  }
`;

const Disabled = styled(Icon)`
  path {
    fill: ${(props) => props.theme.colors.disabledIcon};
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Pulse = styled.div`
  border-radius: 50%;
  position: absolute;
  height: 24px;
  width: 24px;
  animation: pulse-keyframes 4s infinite;
  z-index: 1;

  @keyframes pulse-keyframes {
    0% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(57, 164, 161, 1);
    }
    20% {
      transform: scale(0.1);
      opacity: 0.3;
      box-shadow: 0 0 0 0 rgba(57, 164, 161, 1);
    }

    70% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 0 15px rgba(57, 164, 161, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(57, 164, 161, 0);
    }
  }
`;

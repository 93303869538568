import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import VideoMessage from "components/VideoMessage";
import "./App.css";
import Login from "components/Login";
import { LoginDev } from "components/LoginDev";
import { AppContextProvider, useAppContext } from "AppContextProvider";
import { Checkout } from "components/ShoutoutRequest";
import { Orders } from "components/Orders";
import { useEffect } from "react";
import OrderLookup from "components/OrderLookup";
import ShoutoutOrder from "components/ShoutoutOrder";
import AppLoading from "components/AppLoading";
import { TimeProvider } from "components/TimeProvider";
import { UserProvider } from "components/UserProvider";
import { OrderDetail } from "components/OrderDetail";
import { OrderDetailLookup } from "components/OrderDetailLookup";

const App = () => {
  return (
    <AppContextProvider>
      <UserProvider>
        <TimeProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <Login />
              <Routes />
            </Router>
          </ThemeProvider>
        </TimeProvider>
      </UserProvider>
    </AppContextProvider>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path={"/login"} component={() => null} />
      <Route
        path={"/orders/:eventId/:sessionId/:slotId"}
        component={OrderDetail}
      />
      <Route
        path={"/orders/:eventId/:sessionId"}
        component={OrderDetailLookup}
      />
      <Route path={"/orders"} component={Orders} />
      <Route path={"/:eventId"} component={Checkout} />
      <Route path={"/"} component={OrderLookup} />
    </Switch>
  );
};

export default App;

import React from "react";
import styled, { css } from "styled-components";
import { H4, GridColumn, GridRow, Small, Subheader } from "notes";
import { MediaDownload } from "./MediaDownload";

export const MediaSection = ({
  title,
  subtitle,
  icon,
  photos,
  video,
  ...props
}) => {
  return (
    <SectionContainer {...props}>
      <Row yCenter>
        {icon}
        <H4>{title}</H4>
      </Row>
      <Content video={video}>
        {photos?.map((photo, index) => (
          <>
            <MediaDownload key={index} {...photo.landscape} video={video} />
            <MediaDownload
              portrait
              key={index}
              {...photo.portrait}
              video={video}
            />
          </>
        ))}
      </Content>
    </SectionContainer>
  );
};

const Row = styled(GridRow)`
  ${H4} {
    color: ${(props) => props.theme.palette.gray.lighter};
    flex-grow: 1;
    font-weight: 600;
    margin: 56px 0 12px 0;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${H4} {
      font-size: 17px;
      margin: 40px 0 12px 0;
    }
  }
`;

const SectionContainer = styled(GridColumn)`
  position: relative;
`;

const Content = styled(GridColumn)`
  display: grid;
  grid-template-columns: ${(props) =>
    props.video ? "2fr 1fr" : "repeat(auto-fill, minmax(160px, 1fr))"};
  column-gap: ${(props) => (props.video ? "20px" : "12px")};
  row-gap: 12px;
  height: 100%;
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${(props) =>
      props.video &&
      css`
        grid-template-columns: 1fr;
      `};
  }
`;

import styled, { css } from "styled-components";
import Div from "../Div";
import { Icon } from "notes";

export const sectionPadding = css`
  padding: 32px 52px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const ErrorMessageText = styled(Div)`
  font-size: 15px;
  color: ${(props) => props.theme.colors.inputErrorBorder};
`;

export const ErrorMessage = ({ msg }) => {
  return (
    <ErrorMessageText mt_10 dflex alignCenter>
      <Icon form name="Error" />
      <Div ml_10 mt_3>
        {msg}
      </Div>
    </ErrorMessageText>
  );
};

export const SectionHeader = styled(Div)`
  display: none;
  @media all and ${(props) => props.theme.media.mobile} {
    font-weight: 800;
    font-size: 20px;
    height: 70px;
    margin-top: 0;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.gray05};
    color: ${(props) => props.theme.colors.text};
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const Section = styled(Div)`
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 8px;
  ${sectionPadding};
  color: ${(props) => props.theme.colors.text};
  @media all and ${(props) => props.theme.media.mobile} {
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const MobileDivider = styled(Div)`
  display: none;
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    height: ${(props) => (props.height ? props.height : "4px")};
    width: 100%;
    background-color: #fafafa;
    box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.24);
  }
`;

export const WhiteButton = styled(Div)`
  border: solid 1px ${(props) => props.theme.colors.text};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 40px;
  width: 184px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

import { Section } from "components/common";
import Div from "components/Div";
import { usePaymentContext } from "components/PaymentContextProvider";
import { Children } from "react";
import styled, { css } from "styled-components";
import videoSvg from "../../../assets/video-light.svg";
import { ReactComponent as VideoSvg } from "../../../assets/video-light.svg";
import { H1, P } from "notes";
import { useContext } from "react";
import { CheckoutContext } from "components/ShoutoutRequest";

const Image = styled.img`
  height: 80px;
  width: 80px;
`;

const Container = styled(Section)`
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 25px;
  /* background-image: url(${videoSvg});
  background-size: 155% 155%;
  background-position: 0% 50%; */
  background-repeat: no-repeat;
  @media all and ${(props) => props.theme.media.mobile} {
    border-radius: 0;
    margin-top: 0;
    padding-top: 20px;
    /* background-position: -25% -40%; */
  }
  ${(props) =>
    props.shorterTime &&
    css`
      svg path {
        fill: #ffffff;
      }
      background-color: #e45c52;
      color: #fff;
      ${H1}, ${P} {
        color: #fff !important;
      }
    `}
  ${(props) =>
    props.shortTime &&
    !props.shorterTime &&
    css`
      svg path {
        fill: #e45c52;
      }
      ${H1} {
        color: #e45c52 !important;
      }
    `}
`;

const BackgroundImage = styled(VideoSvg)`
  position: absolute;
  right: 0;
  transform: scaleX(-1) rotate(-40deg) scale(1.1);
  transform-origin: 50% 50%;
  @media only screen and ${(props) => props.theme.media.mobile} {
    top: 21px;
    right: -10px;
  }
`;

const TimeContainer = styled(Container)`
  padding: 24px;
  overflow: hidden;
  position: relative;
`;

const SummaryDetail = () => {
  const { event } = useContext(CheckoutContext);
  return (
    <TimeContainer alignCenter>
      <img src={event?.assets?.squareBanner?.path} width="80" />
      <Div ml_25>
        <Div c_text fs_20 extraBold>
          {event?.artist}
        </Div>
        <Div fs_15 c_border>
          Meet &amp; Greet
        </Div>
      </Div>
      <BackgroundImage />
    </TimeContainer>
  );
};

export const SummaryBox = ({
  children,
  shortTime = false,
  shorterTime = false,
}) => {
  return (
    <TimeContainer
      p_24
      alignCenter
      style={{ backgroundImage: "none" }}
      shortTime={shortTime}
      shorterTime={shorterTime}
    >
      <Div>
        <Div c_text fs_20 extraBold>
          {children}
        </Div>
      </Div>
    </TimeContainer>
  );
};

export default SummaryDetail;

import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import videojs from "video.js";
import Div from "../Div";

let player;

const defaultOptions = {
  muted: false,
  controls: true,
};

const Videojs = ({
  src = null,
  width = "350px",
  height = "620px",
  blob = null,
  autoPlay = false,
  muted = false,
  headerSkin = false,
  autoHideControls = true,
  options = defaultOptions,
  withObjectFitCover = false,
}) => {
  const videoRef = useRef();

  useEffect(() => {
    player = videojs(videoRef.current, {
      fill: true,
      autoplay: autoPlay,
      inactivityTimeout: autoHideControls ? 3000 : 0,
      controlBar: {
        pictureInPictureToggle: false,
      },
      ...options,
    });

    return () => {
      player.dispose();
    };
  }, []);

  useEffect(() => {
    if (src) {
      player.src(src);
      if (muted) player.muted(true);
      if (autoPlay) player.play();
    }
  }, [src]);

  useEffect(() => {
    if (blob) {
      player.src({
        src: window.URL.createObjectURL(blob),
        type: blob.type,
      });
    }
  }, [blob]);

  return (
    <Wrapper positionRelative width={width} height={height}>
      <Video
        muted={muted}
        autoPlay={autoPlay}
        headerSkin={headerSkin}
        playsInline
        withObjectFitCover={withObjectFitCover}
        ref={videoRef}
        className="video-js vjs-big-play-centered"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Div)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const Video = styled<any>("video")`
  outline: none;
  overflow: hidden;
  background: #111113;
  :focus {
    outline: none;
  }
  .vjs-big-play-button {
    background-color: #26a6fa;
    opacity: 1 !important;
    border: none;
  }

  ${(props) =>
    props.headerSkin &&
    css`
      .vjs-control-bar {
        transform: scale(1.3);
        margin-bottom: 15px;
        padding-left: 14%;
        margin-top: -10px;
        background: none !important;
      }
      .vjs-time-divider {
        margin: 0;
        min-width: auto;
        padding: 0;
      }
      .vjs-control-text,
      .vjs-progress-control,
      .vjs-progress-holder,
      .vjs-fullscreen-control,
      .vjs-big-play-button {
        display: none !important;
      }
      .vjs-time-control {
        display: block;
      }
      .vjs-remaining-time {
        display: none;
      }
    `}
  ${(props) =>
    props.withObjectFitCover &&
    css`
      .vjs-tech {
        object-fit: cover;
      }
    `}
`;

export default Videojs;

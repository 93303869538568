import React from "react";
import styled, { css } from "styled-components";
import { GridColumn, Icon, ButtonPrimary } from "notes";
import iOS from "is-ios";

export const MediaDownload = ({
  label,
  source,
  download,
  portrait,
  video,
  thumbnail,
  ...props
}) => {
  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Container portrait={portrait && video} {...props}>
      <Frame>
        <DownloadButton onClick={() => openLink(download)}>
          <Icon tertiary name="Download" />
        </DownloadButton>
        {video ? (
          <Video
            src={source}
            preload="metadata"
            type="video/mp4"
            controls
            poster={iOS ? thumbnail : undefined}
          />
        ) : (
          <Image imageSrc={source} />
        )}
      </Frame>
    </Container>
  );
};

const Container = styled(GridColumn)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${(props) =>
      props.portrait &&
      css`
        width: 50%;
      `};
  }
`;

const DownloadButton = styled(ButtonPrimary)`
  background: rgba(0, 0, 0, 0.72);
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding: 0;
  z-index: 2;
  svg {
    width: 18px;
    height: 18px;
    path {
      fill: #fff;
    }
  }
  &:hover,
  &:focus {
    background: #fff;
    svg path {
      fill: #222;
    }
  }
`;

const Frame = styled(GridColumn)`
  border: 1px solid ${(props) => props.theme.palette.gray.lightest};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const Image = styled.div`
  background-image: url(${(props) => props.imageSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  padding-bottom: 100%;
`;

const Video = styled.video`
  display: block;
  width: 100%;
`;

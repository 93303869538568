export const ShopIcon = ({ width = "16px", height = "16px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon/Form/Shopping</title>
    <g
      id="Icon/Form/Shopping"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M9,12 C9.55228475,12 10,12.4477153 10,13 C10,13.5522847 9.55228475,14 9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 Z M3,12 C3.55228475,12 4,12.4477153 4,13 C4,13.5522847 3.55228475,14 3,14 C2.44771525,14 2,13.5522847 2,13 C2,12.4477153 2.44771525,12 3,12 Z M14.5,2 C14.7761424,2 15,2.22385763 15,2.5 C15,2.77614237 14.7761424,3 14.5,3 L14.5,3 L12.3903882,3 L10.3903882,11 L2,11 C1.72385763,11 1.5,10.7761424 1.5,10.5 C1.5,10.2238576 1.72385763,10 2,10 L2,10 L9.6096118,10 L9.859,9 L2,9 L1,4 L11.109,4 L11.6096118,2 Z M10.109,8 L10.859,5 L2.2198039,5 L2.8198039,8 L10.109,8 Z"
        id="Combined-Shape"
        fill="#596266"
      ></path>
    </g>
  </svg>
);

// TODO: missing on notes library
export const VerticalEllipsis = ({ width = "4", height = "17" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 4 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.95996" cy="1.95996" r="1.95996" fill="#1F1F1F" />
    <circle cx="1.95996" cy="8.48981" r="1.95996" fill="#1F1F1F" />
    <circle cx="1.95996" cy="15.0197" r="1.95996" fill="#1F1F1F" />
  </svg>
);

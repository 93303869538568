import styled from "styled-components";
import Div from "components/Div";
import { CopyToClipboardShareButton, shareMenuItems } from "./share";
import { useState } from "react";

const Modal = styled(Div)`
  width: 80%;
  max-width: 300px;
  background: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  padding: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #222222;
`;

const ModalText = styled(Div)`
  font-weight: 300;
  font-size: 17px;
`;

const DoneButton = styled(Div)`
  color: #7c60d9;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  font-weight: bold;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CopiedToClipboardContainer = styled(Div)`
  background-color: #07090f;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  position: absolute;
  margin-top: -70px;
  border-radius: 4px;
  color: white;
`;

const CopyButton = styled(DoneButton)`
  font-size: 12px;
  width: 50px;
`;

const CopyLinkWrapper = styled(Div)`
  border: 1px solid #a6aeb2;
  border-radius: 4px;
  padding: 7px;
`;

const CopyLinkText = styled(Div)`
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  width: calc(100% - 52px);
`;

const ShareModal = ({ shareUrl = null, onClose }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const onCopyToClipboard = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  const mobileShareButtonsUI = (
    <>
      {shareMenuItems("27")
        .slice(0, 2)
        .map((mi, index) => {
          const Share = mi.shareComponent;
          return (
            <Div key={index} clickable>
              <Share
                style={{ outline: "none" }}
                url={shareUrl || window.location.href}
                onShareWindowClose={onClose}
              >
                {mi.icon}
              </Share>
            </Div>
          );
        })}
    </>
  );

  const copyLinkUI = (
    <CopyLinkWrapper mt_24 clickable>
      <CopyToClipboardShareButton
        url={shareUrl || window.location.href}
        onShareWindowClose={onCopyToClipboard}
      >
        <Div dflex>
          <CopyLinkText>{shareUrl || window.location.href}</CopyLinkText>
          <CopyButton> COPY </CopyButton>
        </Div>
      </CopyToClipboardShareButton>
      {copiedToClipboard && (
        <CopiedToClipboardContainer>Link copied!</CopiedToClipboardContainer>
      )}
    </CopyLinkWrapper>
  );

  return (
    <Modal>
      <Div bold fs_25>
        Share With Friends
      </Div>
      <ModalText mt_5>How would you like to share this?</ModalText>
      <Div centered mt_24>
        <Div spaceBetween w_90>
          {mobileShareButtonsUI}
        </Div>
      </Div>
      {copyLinkUI}
      <DoneButton mt_44 onClick={onClose}>
        Done
      </DoneButton>
    </Modal>
  );
};

export default ShareModal;

import styled from "styled-components";
import { GridColumn, GridRow, H3, P, withDefaultMedia } from "notes";

const Countdown = withDefaultMedia(({ matches, timeToStart }) => {
  const { days, hours, minutes, seconds } = timeToStart;
  return (
    <GridRow stretch>
      <IncrementContainer>
        <Increment>
          {days < 10 && `0`}
          {days}
        </Increment>
        <Label>days</Label>
      </IncrementContainer>

      <IncrementContainer>
        <Increment>
          {hours < 10 && `0`}
          {hours}
        </Increment>
        <Label>{matches.small ? "hrs" : "hours"}</Label>
      </IncrementContainer>

      <IncrementContainer>
        <Increment>
          {minutes < 10 && `0`}
          {minutes}
        </Increment>
        <Label>{matches.small ? "min" : "minutes"}</Label>
      </IncrementContainer>

      <IncrementContainer>
        <Increment>
          {seconds < 10 && `0`}
          {seconds}
        </Increment>
        <Label>{matches.small ? "sec" : "seconds"}</Label>
      </IncrementContainer>
    </GridRow>
  );
});

export default Countdown;

const IncrementContainer = styled(GridColumn)`
  align-items: center;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 38.89%,
      rgba(0, 0, 0, 0.08) 50%,
      #ffffff 50%
    ),
    linear-gradient(
      180deg,
      rgba(230, 233, 235, 0) 16.67%,
      #e6e9eb 50%,
      #ffffff 50%
    ),
    #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  position: relative;
  padding-top: 14px;
  width: 104px;
  height: 96px;
  && + & {
    margin-left: 12px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-radius: 4px;
    padding-top: 11px;
    width: 70px;
    height: 72px;
  }
`;

const Increment = styled(H3)`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 29.85%,
      rgba(0, 0, 0, 1) 61%,
      #222222 62%
    ),
    #222222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
  line-height: 58px;
  margin: 0;
  font-weight: 900;
  width: 100%;
  text-align: center;
  @media only screen and ${(props) => props.theme.media.mobile} {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 29.85%,
        rgba(0, 0, 0, 1) 56%,
        #222222 57%
      ),
      #222222;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    line-height: 42px;
  }
`;

const Label = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 15px;
  line-height: 10px;
  font-weight: 300;
  font-style: italic;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 12px;
    line-height: 10px;
  }
`;

import { FacebookShareButton, TwitterShareButton } from "react-share";
import styled from "styled-components";
import Div from "../Div";
import { Icon } from "notes";
import { theme } from "theme";
import { useState } from "react";

const ShareIcon = styled(Div)`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${(props) => props.fill} !important;
    }
  }
`;

const CopiedToClipboardContainer = styled(Div)`
  background-color: #07090f;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  position: absolute;
  margin-top: -70px;
  border-radius: 4px;
  color: white;
`;

export const CopyToClipboardShareButton = ({
  children,
  url,
  onShareWindowClose = null,
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const onCopyToClipboard = () => {
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
  };

  return (
    <Div
      onClick={() => {
        navigator.clipboard.writeText(url);
        if (onShareWindowClose) onShareWindowClose();
        onCopyToClipboard();
      }}
    >
      {children}
      {copiedToClipboard && (
        <CopiedToClipboardContainer>Link copied!</CopiedToClipboardContainer>
      )}
    </Div>
  );
};

export const shareMenuItems = (dimension = "16") => [
  {
    shareComponent: FacebookShareButton,
    label: "Share on Facebook",
    icon: (
      <ShareIcon fill={theme.colors.facebook}>
        <Icon name="Facebook" width={dimension} height={dimension} />
      </ShareIcon>
    ),
  },
  {
    shareComponent: TwitterShareButton,
    label: "Share on Twitter",
    icon: (
      <ShareIcon fill={theme.colors.twitter}>
        <Icon name="Twitter" width={dimension} height={dimension} />
      </ShareIcon>
    ),
  },
  {
    shareComponent: CopyToClipboardShareButton,
    label: "Copy Link",
    icon: (
      <ShareIcon fill={theme.colors.text}>
        <Icon tertiary name="Link" width={dimension} height={dimension} />
      </ShareIcon>
    ),
  },
];

export const ShareIcons = ({ shareUrl }) => (
  <Div>
    <Div dflex mt_38 justifyEnd alignCenter>
      <Div fs_14 c_subheaderDefault>
        Share:
      </Div>
      {shareMenuItems("24").map((mi, index) => {
        const Share = mi.shareComponent;
        return (
          <Div key={index} clickable>
            <Share style={{ outline: "none" }} url={shareUrl}>
              <Div ml_20>{mi.icon}</Div>
            </Share>
          </Div>
        );
      })}
    </Div>
  </Div>
);

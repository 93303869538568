import firebase from "firebase";

export const useLoginLink = (sessionId) => {
  const open = async () => {
    const link = await firebase.functions().httpsCallable("shoutout-loginUrl")({
      url: process.env.REACT_APP_VIDEO_URL,
      path: `${sessionId}`,
    });
    window.open(link?.data, "_blank");
  };
  return open;
};

import React from "react";
import styled from "styled-components";
import { GridColumn, Icon } from "notes";
import { MediaSection } from "./MediaSection";

export const MediaGallery = ({ videos, photos, ...props }) => {
  const photoArray =
    photos?.map((photo) => ({
      landscape: {
        source: photo?.source?.landscape,
        download: photo?.downloads?.landscape,
      },
      portrait: {
        source: photo?.source?.portrait,
        download: photo?.downloads?.portrait,
      },
    })) ?? [];

  const videoArray = videos?.map((video, index) => ({
    landscape: {
      source: video?.source?.landscape,
      download: video?.downloads?.landscape,
      thumbnail:
        photoArray[index]?.landscape?.source ??
        photoArray[0]?.landscape?.source,
    },
    portrait: {
      source: video?.source?.portrait,
      download: video?.downloads?.portrait,
      thumbnail:
        photoArray[index]?.portrait?.source ?? photoArray[0]?.portrait?.source,
    },
  }));

  return (
    <Container>
      {!!photos?.length && (
        <MediaSection title="Photo(s)" photos={photoArray} />
      )}
      {!!videos?.length && (
        <MediaSection title="Video" photos={videoArray} video />
      )}
    </Container>
  );
};

const Container = styled(GridColumn)``;

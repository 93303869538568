import { useContext } from "react";
import styled from "styled-components";
import { GridColumn, withDefaultMedia } from "notes";
import { StepBullets } from "./StepBullets";
import { useCompositions } from "components/useCompositions";
import { useDocument } from "react-firebase-hooks/firestore";
import { UserContext } from "components/UserProvider";
import { MediaGallery } from "components/MediaGallery";
import firebase from "firebase";

export const CloudTracker = withDefaultMedia(({ matches, eventId }) => {
  const { user } = useContext(UserContext);
  const { status: twilioStatus, progress, sessions } = useCompositions(eventId);
  const status =
    twilioStatus === "composition-progress"
      ? 2
      : twilioStatus === "composition-available"
      ? 3
      : 1;

  const [photos] = useDocument(
    firebase
      .firestore()
      .collection(`meet_greet/${eventId}/rooms/${user?.uid}/photos`)
  );

  const photosArray = photos?.docs
    .map((photo) => photo.data())
    .filter(({ downloads }) => !!downloads);

  const steps = [
    {
      status: status > 1 ? "complete" : "active",
      label: "Uploading",
    },
    {
      status: status === 2 ? "active" : status > 2 ? "complete" : "",
      label: "Formatting",
    },
    {
      status: status === 3 ? "complete" : "",
      label: "Ready",
    },
  ];

  return (
    <Container>
      <StepBullets steps={steps} />
      {status === 3 && (
        <MediaGallery videos={sessions || []} photos={photosArray || []} />
      )}
    </Container>
  );
});

const Container = styled(GridColumn)`
  margin: 40px 0 0 0;
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin: 32px 0 0 0;
  }
`;

import styled, { css } from "styled-components";
import Div from "../Div";
import { AppContainer } from "./layout";
import { Link } from "notes";
import Logo from "./Logo";
import { theme } from "theme";

const Container = styled(Div)`
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  padding-top: 30px;
  svg {
    path {
      fill: ${(props) => props.color};
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    padding-bottom: 10px;
  }
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.color};
  :hover,
  :focus {
    color: ${(props) => props.color};
  }
  margin-right: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 20px;
  }
`;

const Wrapper = styled(Div)`
  width: 100%;
  ${(props) =>
    props.withLogo
      ? css`
          display: flex;
          justify-content: space-between;
        `
      : css`
          display: grid;
          place-items: center;
        `}
  padding-bottom: 35px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    padding-bottom: 4%;
  }
`;

const LinksWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 10px;
  }
`;

const links = [
  {
    label: "Privacy Policy",
    path: "/privacy",
  },
  {
    label: "Terms of Use",
    path: "/terms",
  },
  {
    label: "Contact Us",
    path: "/contact",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
];

const Footer = ({
  withLogo = true,
  color = theme.colors.text,
  background = theme.colors.background,
}) => {
  const gotoPage = (path) => {
    window.open(`https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}${path}`);
  };

  const copyrightUI = (
    <Div fs_12 c_hA6AEB2 mb_10>
      © 2021 Set.Live
    </Div>
  );

  return (
    <Container color={color} background={background} mcontainer>
      <AppContainer>
        <Wrapper withLogo={withLogo}>
          <Div>
            {withLogo && <Logo />}
            {copyrightUI}
          </Div>
          <LinksWrapper flexWrap>
            {links.map((link, index) => (
              <FooterLink
                color={color}
                background={background}
                clickable
                key={index}
                onClick={() => {
                  gotoPage(link.path);
                }}
              >
                {link.label}
              </FooterLink>
            ))}
          </LinksWrapper>
        </Wrapper>
      </AppContainer>
    </Container>
  );
};

export default Footer;

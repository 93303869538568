import Div from "components/Div";
import { Input, NameInput } from "components/common/formikInputs";
import styled from "styled-components";
import { Section } from ".";
import { Icon } from "notes";
import { Backdrop, ModalContainer } from "components/common/overlay";
import { useState } from "react";
import { useMediaQuery } from "hooks";
import { theme } from "theme";

export const Wrapper = styled(Div)`
  width: 400px;
  padding-top: 30px;
  padding-bottom: 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    padding-top: 20px;
  }
`;

const Label = styled(Div)`
  font-size: 15px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.text};
`;

const ModalWrapper = styled(Div)`
  font-size: 17px;
  color: ${(props) => props.theme.colors.text};
  padding: 16px 24px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  width: 392px;
  border-radius: 8px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 75%;
  }
`;

const OKButton = styled(Div)`
  margin-top: 10px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

const ModalBackdrop = styled(Backdrop)`
  background: ${(props) => props.theme.colors.border};
  opacity: 0.2;
`;

const ContactInformation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.media.mobile);
  const inputWidth = isMobile ? "100%" : "392px";

  const modalUI = (
    <>
      <ModalBackdrop
        onClick={() => {
          setIsModalOpen(false);
        }}
      />
      <ModalContainer>
        <ModalWrapper>
          <Div bold mb_7>
            Why Do You Need My Contact Information
          </Div>
          <Div lite>
            Your contact information will be used to help you track the status
            of your Shout Out, to receive the Shout Out link and if the Set.Live
            needs to reach out to you about your order.
          </Div>
          <OKButton flexRowReverse bold c_linkDefault>
            <Div
              c_linkDefault
              clickable
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Got it
            </Div>
          </OKButton>
        </ModalWrapper>
      </ModalContainer>
    </>
  );

  return (
    <Section centered mcontainer>
      {isModalOpen && modalUI}
      <Wrapper>
        <Div fs_17 c_text bold mb_20>
          Contact Information
        </Div>
        <Label>Name</Label>
        <Div dflex>
          <NameInput />
        </Div>
        <Div mt_30>
          <Label>Email address</Label>
          <Input width={inputWidth} name="email" placeholder="john@email.com" />
        </Div>
        <Div
          alignCenter
          mt_20
          clickable
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <Icon indicator name="Information" />
          <Div ml_7 fs_14 c_text>
            Why do you need to know this?
          </Div>
        </Div>
      </Wrapper>
    </Section>
  );
};

export default ContactInformation;

import { useAppContext } from "AppContextProvider";
import { UserContext } from "components/UserProvider";
import { getObjectByString } from "components/utils";
import { fstore } from "firebase-tools";
import { createContext, useContext, useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase";

const OrdersContext = createContext(null);

const compare = (request, searchText) => {
  const match = (field) =>
    getObjectByString(request, field)
      ?.toLowerCase()
      .includes(searchText.toLowerCase());

  return (
    match("shoutout.name") ||
    match("email") ||
    match("form.starter") ||
    match("form.requestedBy") ||
    match("form.messageFor") ||
    match("form.message") ||
    match("form.relation")
  );
};

export const OrdersContextProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [searchText, setSearchText] = useState(null);
  const [requests, l, e] = useCollectionData(
    firebase
      .firestore()
      .collectionGroup("slots")
      .where("requestorId", "==", user.uid),
    { idField: "id" }
  );

  const [recipientRequests, lr, er] = useCollectionData(
    firebase
      .firestore()
      .collectionGroup("slots")
      .where("recipientId", "==", user.uid)
      .where("requestorId", "!=", user.uid),
    { idField: "id" }
  );

  const combined = [...(requests || []), ...(recipientRequests || [])];

  const orders = searchText
    ? combined?.filter((r) => compare(r, searchText))
    : combined;
  return (
    <OrdersContext.Provider
      value={{
        orders: orders || [],
        isLoading: false,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrdersContext = () => useContext(OrdersContext);

import Div from "components/Div";
import { useField, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { Radio, Select, TextAreaCounter, Input as InputBase } from "notes";
import { ErrorMessage as ErrorMessageBase } from "notes";
import { ErrorMessage } from ".";

const LeftField = styled(InputBase)`
  border-radius: 4px 0 0 4px;
  border-right: none;
`;

const RightField = styled(InputBase)`
  border-radius: 0 4px 4px 0;
`;

const InputWrapper = styled(Div)`
  max-width: ${(props) => props.width};
`;

const MessageForInputWrapper = styled(Div)`
  display: flex;
  margin-top: 10px;
  user-select: none;
  font-weight: 400;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 500;
    `}
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    border: solid 1px ${(props) => props.theme.colors.border};
    border-radius: 4px;
    height: 40px;
    padding: 0 13px;
    display: flex;
    align-items: center;
    :hover {
      border: solid 1px ${(props) => props.theme.colors.linkDefault};
      background: ${(props) => props.theme.colors.actionLight};
    }
  }
`;

const errorMessageUI = (
  <Div c_inputErrorBorder>
    Please review the highlighted errors before attempting your submission
    again.
  </Div>
);

const ErrorWarningMessage = styled(ErrorMessageBase)`
  width: 100%;
  margin: 0;
`;

export const ErrorMessageWarning = () => {
  const { submitCount, touched, errors } = useFormikContext();

  const formIsDisplayingErrors = (touched, errors) => {
    let res = false;
    const fields = Object.keys(touched);
    for (let i = 0; i < fields.length; i++) if (errors[fields[i]]) res = true;
    return res;
  };

  return (
    <>
      {submitCount > 0 && formIsDisplayingErrors(touched, errors) && (
        <Div centered>
          <ErrorWarningMessage content={errorMessageUI} />
        </Div>
      )}
    </>
  );
};

export const MessageForInput = ({ name, options, onSelect = null }) => {
  const [field, meta, { setValue }] = useField(name);
  return (
    <Div>
      {options.map((opt, index) => {
        const isSelected = field.value === opt.value;
        return (
          <MessageForInputWrapper
            isSelected={isSelected}
            key={index}
            onClick={() => {
              setValue(opt.value);
              if (onSelect) onSelect(opt.value);
            }}
          >
            <Radio onChange={() => {}} checked={isSelected} />
            <Div>{opt.label}</Div>
          </MessageForInputWrapper>
        );
      })}
    </Div>
  );
};

const useDisplayErrorMessage = (value) => {
  const { submitCount } = useFormikContext();
  if ((value === null || value === "") && submitCount === 0) return false;
  return true;
};

export const NameInput = () => {
  const firstNameField = useField("firstName");
  const lastNameField = useField("lastName");

  const shouldDisplayError1 = useDisplayErrorMessage(firstNameField[0].value);
  const shouldDisplayError2 = useDisplayErrorMessage(lastNameField[0].value);

  const fieldCotainsError = (field) =>
    field[1].touched && field[1].error && field[1].error !== "";
  const displayError =
    fieldCotainsError(firstNameField) || fieldCotainsError(lastNameField);
  const error = firstNameField[1]?.error || lastNameField[1]?.error;

  return (
    <Div w100>
      <Div dflex>
        <LeftField
          name="firstName"
          error={
            shouldDisplayError1 &&
            displayError &&
            fieldCotainsError(firstNameField)
          }
          value={firstNameField[0]?.value || ""}
          placeholder="First or given name..."
          onBlur={() => firstNameField[2].setTouched(true)}
          onChange={(v) => firstNameField[2].setValue(v)}
        />
        <RightField
          name="lastName"
          error={
            displayError &&
            shouldDisplayError2 &&
            fieldCotainsError(lastNameField)
          }
          value={lastNameField[0]?.value || ""}
          placeholder="Last or family name..."
          onBlur={() => lastNameField[2].setTouched(true)}
          onChange={(v) => lastNameField[2].setValue(v)}
        />
      </Div>
      {shouldDisplayError1 && shouldDisplayError2 && displayError && (
        <ErrorMessage msg={error} />
      )}
    </Div>
  );
};

export const Input = ({
  name,
  placeholder,
  leftIcon = null,
  width = "392px",
}) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);
  const shouldDisplayError = useDisplayErrorMessage(field.value);
  const displayError = shouldDisplayError && touched && error && error !== "";

  return (
    <>
      <InputWrapper width={width} name={name} tabIndex="0">
        <InputBase
          name={name}
          leftIcon={leftIcon}
          error={displayError}
          value={field?.value || ""}
          placeholder={placeholder}
          onBlur={() => setTouched(true)}
          onChange={(v) => setValue(v)}
        />
      </InputWrapper>
      {displayError && <ErrorMessage msg={error} />}
    </>
  );
};

export const SelectInput = ({
  name,
  options,
  placeholder = "",
  width = "392px",
}) => {
  const [field, { error, touched }, { setValue }] = useField(name);
  const shouldDisplayError = useDisplayErrorMessage(field.value);
  const displayError = shouldDisplayError && touched && error && error !== "";
  return (
    <>
      <InputWrapper width={width} name={name} tabIndex="0">
        <Select
          error={displayError}
          placeholder={placeholder}
          options={options}
          selected={options.find((o) => o.id == field.value) || ""}
          onChange={(o) => {
            setValue(o.id);
          }}
        />
      </InputWrapper>

      {displayError && <ErrorMessage msg={error} />}
    </>
  );
};

export const TextareaInput = ({ name, placeholder, charLimit = "128" }) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);
  const shouldDisplayError = useDisplayErrorMessage(field.value);
  const displayError = shouldDisplayError && touched && error && error !== "";

  return (
    <Div name={name} tabIndex="0">
      <TextAreaCounter
        name={name}
        value={field.value || ""}
        error={displayError}
        placeholder={placeholder}
        onBlur={() => setTouched(true)}
        onChange={(v) => setValue(v)}
        charLimit={charLimit}
      />
      {displayError && <ErrorMessage msg={error} />}
    </Div>
  );
};

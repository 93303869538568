import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import firebase from "firebase-tools";
import { useAppContext } from "AppContextProvider";

const DEFAULT_REDIRECT = "/";

interface LoginParams {
  /**
   * Email of user
   */
  email: string;

  /**
   * Target path for redirect
   */
  to: string;
}

const getParams = (search: Location["search"]): LoginParams => {
  const parsed = new URLSearchParams(search);

  const params = {
    email: parsed.get("email") || "",
    to: parsed.get("to") || DEFAULT_REDIRECT,
  };

  return params;
};

/**
 * Logs in the user using the email link login URL generated by Firebase.
 */
const Login = () => {
  const history = useHistory();
  const { user } = useAppContext();
  const location = useLocation();
  const { setAppIsLoading } = useAppContext();

  useEffect(() => {
    (async () => {
      if (firebase.auth().isSignInWithEmailLink(location.search)) {
        // Link is a sign-in link, let's try to sign in with it
        const params = getParams(location.search);
        try {
          setAppIsLoading(true);
          await firebase
            .auth()
            .signInWithEmailLink(params.email, location.search);
          setAppIsLoading(false);
          history.push(params.to);
        } catch (error) {
          console.error(error);
          if (
            firebase.auth().currentUser.email &&
            firebase.auth().currentUser.email !== params.email
          ) {
            await firebase.auth().signOut();
          }
          if (params.to.includes("orders")) {
            history.push(
              params.to +
                `?error=expired&email=${encodeURIComponent(params.email)}`
            );
          } else {
            history.push("/");
          }
        }
      }
    })();
  }, []);

  return null;
};

export default Login;

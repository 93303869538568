import Div from "components/Div";
import { formatCurrency } from "components/formatCurrency";
import { usePaymentContext } from "components/PaymentContextProvider";
import { CheckoutContext } from "components/ShoutoutRequest";
import { getDisplayPrice } from "components/utils";
import { useContext } from "react";
import styled, { css } from "styled-components";
import { Section } from ".";

const Divider = styled(Div)`
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  height: 1px;
`;

const Label = styled(Div)`
  font-size: 17px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
`;

const mobilePaddingCss = css`
  @media all and ${(props) => props.theme.media.mobile} {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const OrderSubTotal = styled(Div)`
  padding: 14px 20px;
  ${mobilePaddingCss}
`;

const OrderTotal = styled(Div)`
  padding: 18px 20px;
  ${mobilePaddingCss}
`;

const ProcessingFee = styled(Div)`
  padding: 18px 20px 5px 20px;
  ${mobilePaddingCss}
`;

const SummaryValues = () => {
  const { amount, price, fee } = useContext(CheckoutContext);
  return (
    <Section>
      <OrderSubTotal spaceBetween alignItemsEnd>
        <Div mt_5 dflex alignCenter>
          <Label>Subtotal</Label>
        </Div>
        <Div dflex alignItemsEnd>
          <Div fs_17 extraBold>
            {formatCurrency(price)}
          </Div>
        </Div>
      </OrderSubTotal>
      <Divider />
      <ProcessingFee spaceBetween>
        <Div c_gray30 fs_17>
          Processing Fee
        </Div>
        <Div c_gray30 fs_17>
          {formatCurrency(fee)}
        </Div>
      </ProcessingFee>
      <OrderTotal spaceBetween alignItemsEnd>
        <Div dflex>
          <Label mt_n30>Total</Label>
        </Div>
        <Div dflex alignItemsEnd>
          <Div mb_5 fs_14 mr_5 bold>
            USD
          </Div>
          <Div fs_25 style={{ fontWeight: 900 }}>
            {formatCurrency(amount)}
          </Div>
        </Div>
      </OrderTotal>
    </Section>
  );
};

export default SummaryValues;

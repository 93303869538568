import styled from "styled-components";
import Div from "components/Div";
import { AppContainer, AppLayout } from "components/common/layout";
import Header, {
  HeaderText,
  HeaderTextContainer,
} from "components/common/Header";
import Footer from "components/common/Footer";
import { useEffect } from "react";
import { theme } from "theme";
import OrderItem from "./OrderItem";
import { Input, Icon } from "notes";
import { MobileDivider } from "components/common";
import { useAppContext } from "AppContextProvider";
import backgroundImageUrl from "../../assets/orders-page-background.jpg";
import {
  OrdersContextProvider,
  useOrdersContext,
} from "./OrdersContextProvider";

const HeaderContainer = styled(HeaderTextContainer)`
  margin-bottom: 100px;
  margin-left: 60px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    margin-bottom: 50px;
  }
`;

const SearchInputWrapper = styled(Div)`
  width: 392px;
  margin-top: 40px;
  position: relative;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 95%;
    margin-top: 0px;
    padding-left: 2%;
  }
`;

const NoResults = styled(Div)`
  margin-top: 40px;
  color: ${(props) => props.theme.colors.inputPlaceholderText};
  span {
    font-weight: bold;
  }
`;

const SearchInput = styled(Input)`
  @media all and ${(props) => props.theme.media.mobile} {
    border: none;
    border-radius: 0;
    :focus,
    :active,
    :disabled {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
`;

const OrderItemWrapper = styled(Div)`
  margin-top: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0px;
  }
`;

export const Orders = () => {
  return (
    <OrdersContextProvider>
      <OrdersWithContext />
    </OrdersContextProvider>
  );
};

const OrdersWithContext = () => {
  const { orders, isLoading } = useOrdersContext();
  const { setAppIsLoading } = useAppContext();

  useEffect(() => {
    setAppIsLoading(isLoading);
  }, [isLoading]);

  return <>{!isLoading && <OrdersWithData orders={orders} />}</>;
};

const OrdersWithData = ({ orders }) => {
  const { searchText, setSearchText } = useOrdersContext();
  const { user } = useAppContext();

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.white;
  }, []);

  const email = user.email;

  const headerUI = (
    <HeaderContainer>
      <HeaderText text="Your Orders" description={email} />
    </HeaderContainer>
  );

  const rightIcon = (
    <>
      {searchText && (
        <Div clickable mt_n2 onClick={() => setSearchText(null)}>
          <Icon form name="RemoveCircle" />
        </Div>
      )}
    </>
  );

  const searchInputUI = (
    <SearchInputWrapper>
      <SearchInput
        leftIcon={
          <Div mt_n2>
            <Icon form name="Search" />
          </Div>
        }
        rightIcon={rightIcon}
        value={searchText || ""}
        placeholder="Search for an order by artist or keyword..."
        onChange={(v) => setSearchText(v)}
      />
    </SearchInputWrapper>
  );

  const emptyOrdersUI = orders.length === 0 && (
    <NoResults mcontainer>
      {searchText ? (
        <>
          No items matching &quot;<span>{searchText}</span>&quot; were found.
          Please check your spelling or try a new search…
        </>
      ) : (
        <>No items were found</>
      )}
    </NoResults>
  );

  const ds = (d) => d.createdAt.seconds;
  return (
    <AppLayout>
      <Header backgroundImageUrl={backgroundImageUrl}>{headerUI}</Header>
      <AppContainer>
        <Div mb_80>
          {searchInputUI}
          <MobileDivider />
          {orders.map((order) => (
            <OrderItemWrapper key={order.id}>
              <OrderItem order={order} />
              <MobileDivider height="1px" />
            </OrderItemWrapper>
          ))}
          {emptyOrdersUI}
        </Div>
      </AppContainer>
      <Footer background={theme.colors.white} />
    </AppLayout>
  );
};

import { useContext } from "react";
import firebase from "firebase";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { UserContext } from "components/UserProvider";

export const useCompositions = (eventId) => {
  const { user } = useContext(UserContext);

  const [userSessions, loading, error] = useCollectionData(
    firebase
      .firestore()
      .collection(`meet_greet/${eventId}/rooms/${user?.uid}/sessions`)
      .orderBy("createdAt", "desc")
  );

  console.log(
    userSessions,
    `meet_greet/${eventId}/rooms/${user?.uid}/sessions`
  );

  const allSessions =
    userSessions?.filter(({ artist, fan }) => !!artist?.sid && !!fan?.sid) ??
    [];

  const session: any = allSessions?.length ? allSessions[0] : {};

  const sessionId = session?.sid;

  const [compositions, cLoading, cError] = useCollectionData(
    firebase
      .firestore()
      .collection(
        `meet_greet/${eventId}/rooms/${user?.uid}/sessions/${sessionId}/composition`
      )
  );

  if (loading) return {};

  const hasCompositions = !!compositions?.length;

  const landscape: any =
    compositions?.find(({ orientation }) => orientation === "landscape") ?? {};
  const portrait: any =
    compositions?.find(({ orientation }) => orientation === "portrait") ?? {};

  let status = "enqueued";
  let progress = 0;

  if (!!landscape?.progress || !!portrait?.progress) {
    const lValue = landscape?.progress ?? 0;
    const pValue = portrait?.progress ?? 0;
    progress = (parseInt(lValue) + parseInt(pValue)) / 2;
    status = "composition-progress";
  }

  if (
    session?.status === "complete" ||
    (!!session?.downloads?.portrait && !!session?.downloads?.landscape)
  ) {
    progress = 100;
    status = "composition-available";
  }

  const sessions = allSessions?.length
    ? allSessions
        ?.map(({ downloads, source }) => ({ downloads, source }))
        .filter(
          ({ downloads }) => !!downloads?.portrait && !!downloads?.landscape
        )
    : [];

  return { status, progress, sessions, hasCompositions };
};

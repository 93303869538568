import styled, { css } from "styled-components";
import Div from "components/Div";
import { MobileDivider, Section, sectionPadding } from "components/common";
import {
  CreditCardBadge,
  ButtonPrimary,
  Subheader,
  Link,
  P,
  H1,
  Footnote,
  Icon,
  CountryPhone,
  H3,
  H4,
  Small,
  Logo,
} from "notes";
import { useState } from "react";
import ShoutoutForm from "components/common/ShoutoutForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { NameInput } from "components/common/formikInputs";
import { useContext } from "react";
import { DateTime, Duration } from "luxon";
import Countdown from "components/common/Countdown";
import { TimeContext } from "components/TimeProvider";
import { ReactComponent as IconVideoChat } from "assets/IconVideoChat.svg";
import { ReactComponent as IconShopping } from "assets/IconShopping.svg";
import { formatCurrency } from "components/formatCurrency";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import Header, {
  HeaderTextContainer,
  HeaderText,
  ContentColumns,
} from "components/common/Header";
import { AppContainer, AppLayout } from "components/common/layout";
import backgroundImageUrl from "../assets/orders-page-background.jpg";
import Footer from "./common/Footer";
import { useMediaQuery } from "hooks";
import { theme } from "theme";
import { UserContext } from "./UserProvider";
import { CloudTracker } from "./CloudTracker";
import { useLoginLink } from "./useLoginLink";

const HeaderContainer = styled(HeaderTextContainer)`
  margin-bottom: 100px;
  margin-left: 60px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    margin-bottom: 50px;
  }
`;

const Content = styled(Div)`
  padding: 32px 52px 40px;
  position: relative;
  z-index: 1;
  ${H1} {
    display: flex;
    align-items: center;
    font-family: "Overpass";
    font-size: 32px;
    font-weight: 800;
    text-transform: none;
    margin: 0 0 24px 0;
    svg {
      margin-right: 16px;
      width: 40px;
      height: 40px;
      path {
        fill: #222222;
      }
    }
  }
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 12px;
  }
  & > ${H3} {
    display: flex;
    font-weight: 800;
    margin: 0;
  }
  & > div > ${H4} {
    font-weight: 700;
    margin: 0;
    text-align: center;
  }
  ${Subheader} {
    font-size: 17px;
    line-height: 22px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
  }
  ${P} {
    span {
      font-weight: 600;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 24px 0;
    ${H1} {
      font-size: 25px;
      line-height: 32px;
      svg {
        width: 22px;
        height: 22px;
      }
    }
    & > div > ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
    & > ${H3} {
      font-size: 20px;
      line-height: 26px;
      & > svg {
        display: none;
      }
    }
    ${Subheader} {
      font-size: 15px;
      line-height: 19px;
      margin-top: 16px;
    }
  }
`;

const ContentMargins = styled(Content)`
  p + p {
    margin-top: ${(props) => (props.large ? "12px" : "4px")};
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    p {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const Wrapper = styled(Section)`
  padding: 0 !important;
  border: none;
  margin-top: 24px;
  overflow: hidden;
  ${(props) =>
    props.isRequestRejected &&
    css`
      margin-top: -100px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0;
  }
`;

const Button = styled(ButtonPrimary)`
  justify-content: space-between;
  margin-top: 40px;
  padding: 9px 24px 9px 16px;
  width: 392px;
  height: 56px;
  svg {
    width: 21px;
    height: 21px;
    path {
      fill: #ffffff;
    }
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
    }
  }
  &:disabled {
    border: 2px solid ${(props) => props.theme.palette.gray.lighter};
    svg path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const WarningIcon = styled(Div)`
  color: ${(props) => props.theme.colors.orange40};
`;

export const OrderDetail = ({
  match: {
    params: { eventId, sessionId, slotId },
  },
}) => {
  const { time } = useContext(TimeContext);
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.media.mobile);
  const [phone, setPhone] = useState("");
  const [editPhone, setEditPhone] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  const [event, l1]: any = useDocumentData(
    firebase.firestore().doc(`/meet_greet_events/${eventId}`)
  );
  const [session, l2]: any = useDocumentData(
    firebase.firestore().doc(`/meet_greet/${sessionId}`)
  );
  const [slot, l3]: any = useDocumentData(
    firebase.firestore().doc(`/meet_greet/${sessionId}/slots/${slotId}`)
  );
  const [transaction, l4]: any = useDocumentData(
    firebase
      .firestore()
      .doc(`/meet_greet/${sessionId}/slots/${slotId}/private/data`)
  );
  const [roomDoc, l5]: any = useDocument(
    firebase
      .firestore()
      .doc(`/meet_greet/${sessionId}/rooms/${slot?.recipientId}`)
  );
  const [roomDocPrivate, l6]: any = useDocument(
    firebase
      .firestore()
      .doc(`/meet_greet/${sessionId}/rooms/${slot?.recipientId}/private/data`)
  );

  const loginLink = useLoginLink(sessionId);

  if (l1 || l2 || l3 || l4 || l5 || l6) {
    return null;
  }

  const room = roomDoc?.data();
  const roomPrivate = roomDocPrivate?.data();

  const savePhone = () => {
    roomDocPrivate?.ref?.update({ phone });
    setEditPhone(false);
  };

  const isBuyer = slot?.requestorId === user?.uid;
  const isParticipant = slot?.recipientId === user?.uid;

  const handleLinkClick = async () => {
    setLinkLoading(true);
    loginLink().finally(() => {
      setLinkLoading(false);
    });
  };

  const viewShoutoutDesktopUI = (
    <>
      <SocialContainer mt_32 dflex justifyEnd alignCenter>
        <Subheader>Share:</Subheader>
        <Logo name="FacebookDefault" />
        <Logo name="TwitterDefault" />
        <Icon tertiary name="Link" />
      </SocialContainer>
      <SidebarWrapper>
        <H4>What to Expect</H4>
        <ul>
          <li>
            At your scheduled time slot, you will login and be queued up to meet
            the artist. Typically you can login up to 10 minutes before the meet
            & greet starts.
          </li>
          {event?.checkoutWhatToExpect?.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
          <li>
            Please review the{" "}
            <Link style={{ display: "inline" }}>
              minimum technical requirements
            </Link>{" "}
            and check your audio and video ahead of time. Typically a laptop
            connected to a broadband Wi-Fi is recommended.
          </li>
          <li>
            Please be on time - the artist has allotted a certain amount of time
            to meet fans. If you cannot make your scheduled time slot or cannot
            get your audio and video to work, there will not be a refund.
          </li>
        </ul>
      </SidebarWrapper>
    </>
  );

  const endTime = DateTime.fromJSDate(session?.endDate?.toDate());
  const formattedTime = DateTime.fromJSDate(session?.startDate?.toDate());
  const timeToStart =
    formattedTime &&
    session &&
    Duration.fromMillis((formattedTime as any) - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  return (
    <AppLayout>
      <Header
        backgroundImageUrl={
          isMobile
            ? event?.assets?.squareBanner?.path
            : event?.assets?.preshowBanner?.path
        }
      >
        <HeaderContainer>
          <HeaderText text={event?.artist} />
        </HeaderContainer>
      </Header>
      <AppContainer>
        <ContentColumns>
          {endTime > time ? (
            <Div>
              {isParticipant && (
                <Wrapper c_text positionRelative>
                  <Div mcontainer>
                    <Content alignCenter dflexColumn>
                      <Div alignCenter>
                        <H1>
                          <Icon form name="VideoChat" />
                          Meet &amp; Greet Booked!
                        </H1>
                      </Div>
                      {formattedTime > time && (
                        <>
                          <Small>Call Starts In</Small>
                          {timeToStart && (
                            <Countdown timeToStart={timeToStart} />
                          )}
                        </>
                      )}
                      <Button onClick={handleLinkClick} loading={linkLoading}>
                        <span>
                          Join Call
                          <span>
                            {DateTime.fromJSDate(
                              session?.startDate?.toDate()
                            ).toFormat("DDD")}{" "}
                            at{" "}
                            {DateTime.fromJSDate(
                              session?.startDate?.toDate()
                            ).toFormat("h:mm a ZZZZ")}
                          </span>
                        </span>{" "}
                        <Icon tertiary name="Export" />
                      </Button>
                    </Content>
                    <BackgroundImage />
                  </Div>
                </Wrapper>
              )}
              {isParticipant && (
                <Wrapper c_text positionRelative>
                  <Div mcontainer>
                    <ContentMargins large>
                      <H3>What Comes Next?</H3>
                      <P>
                        Your reservation is booked! Please check your email for
                        your ticket - you should receive it within a few
                        minutes. Your ticket as well as future updates will be
                        sent to{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {user?.email}
                        </span>
                        . If you cannot find it in your email or the email
                        listed above is incorrect, please let our customer
                        service team know.
                      </P>
                      <P>
                        Your ticket will include a link to the Meet & Greet
                        event. If you accidentally delete your email, you may
                        visit artists.set.live/order-lookup to be sent a
                        replacement ticket to{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {user?.email}
                        </span>
                        .
                      </P>

                      <P>
                        Please review the minimum technical requirements and
                        check your audio and video ahead of time. Typically a
                        laptop connected to a broadband Wi-Fi is recommended,
                        though many smartphones and tablets are also supported.
                      </P>
                      <P>
                        Please be on time - the artist has allotted a certain
                        amount of time to meet fans. If you cannot make your
                        scheduled time slot or cannot get your audio and video
                        to work, there will not be a refund.
                      </P>
                      <Div mt_32 mb_32 dflex>
                        <Divider />
                      </Div>
                      {!roomPrivate?.phone || editPhone ? (
                        <Div>
                          <Div fs_17 mb_8 style={{ fontWeight: 800 }}>
                            Get meet &amp; greet updates by text
                          </Div>
                          <PhoneFileContainer dflex>
                            <CountryPhone
                              style={{ flexGrow: 1 }}
                              value={phone}
                              onChange={setPhone}
                            />
                            <ButtonPrimary onClick={savePhone}>
                              Submit
                            </ButtonPrimary>
                          </PhoneFileContainer>
                        </Div>
                      ) : (
                        <P>
                          You will receive text updates on your request at{" "}
                          <span>{roomPrivate?.phone}</span>{" "}
                          <Link onClick={() => setEditPhone(true)}>(edit)</Link>
                        </P>
                      )}
                      <Div mt_16>
                        <Footnote>
                          Message and data rates may apply. Consent is not a
                          condition of purchase. Text back STOP to cancel.
                        </Footnote>
                      </Div>
                    </ContentMargins>
                  </Div>
                </Wrapper>
              )}

              <Wrapper c_text positionRelative>
                <ContentMargins>
                  <H3>Booking Details</H3>
                  <Subheader>Participant Information</Subheader>
                  <P>{transaction?.intent?.metadata?.recipientName}</P>
                  <P>{transaction?.intent?.metadata?.recipientEmail}</P>
                  <Subheader>Meet &amp; Greet Information</Subheader>
                  <P>
                    {DateTime.fromJSDate(session?.startDate?.toDate()).toFormat(
                      "DD (cccc)"
                    )}
                  </P>
                  <P>
                    {DateTime.fromJSDate(session?.startDate?.toDate()).toFormat(
                      "h a ZZZZ"
                    )}
                  </P>
                </ContentMargins>
              </Wrapper>
              {isBuyer && (
                <Wrapper c_text positionRelative>
                  <Content>
                    <H3>
                      <ShoppingIcon /> Order Summary
                    </H3>
                    <div
                      style={{
                        display: "flex",
                        padding: "56px 0 24px 0",
                      }}
                    >
                      <img
                        src={event?.assets?.squareBanner?.path}
                        width="108"
                      />
                      <H3
                        style={{
                          fontSize: "20px",
                          paddingLeft: "24px",
                          margin: 0,
                        }}
                      >
                        Meet & Greet with {event?.artist}
                      </H3>
                      <H3
                        style={{
                          fontSize: "17px",
                          flexGrow: "1",
                          textAlign: "right",
                          margin: 0,
                        }}
                      >
                        {formatCurrency(transaction?.intent?.amount)}
                      </H3>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <H3 style={{ fontSize: "17px" }}>Subtotal</H3>
                      <H3 style={{ fontSize: "17px" }}>
                        {formatCurrency(
                          parseInt(transaction?.intent?.metadata?.subtotal)
                        )}
                      </H3>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <H3
                        style={{
                          fontSize: "17px",
                          color: "#596266",
                          marginTop: 0,
                        }}
                      >
                        Processing Fee
                      </H3>
                      <H3
                        style={{
                          fontSize: "17px",
                          color: "#596266",
                          marginTop: 0,
                        }}
                      >
                        {formatCurrency(
                          parseInt(transaction?.intent?.metadata?.processingFee)
                        )}
                      </H3>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <H3
                        style={{
                          fontSize: "17px",
                          height: "17px",
                          paddingTop: "17px",
                        }}
                      >
                        Total
                      </H3>
                      <H3 style={{ fontSize: "25px" }}>
                        <span style={{ fontSize: "14px" }}>USD</span>
                        {formatCurrency(
                          parseInt(transaction?.intent?.metadata?.total)
                        )}
                      </H3>
                    </div>
                  </Content>
                </Wrapper>
              )}
              {isBuyer && (
                <Wrapper c_text positionRelative>
                  <ContentMargins>
                    <H3>Customer Information</H3>
                    <Subheader>Billing Information</Subheader>
                    <P>{transaction?.intent?.metadata?.customerEmail}</P>
                    <Subheader>Payment Method</Subheader>

                    <CreditCard>
                      <CreditCardBadge
                        type={transaction?.source?.metadata?.card?.brand}
                      />
                      {transaction?.source?.metadata?.card?.brand?.toUpperCase()}{" "}
                      ending in {transaction?.source?.metadata?.card?.last4}
                    </CreditCard>
                  </ContentMargins>
                </Wrapper>
              )}
            </Div>
          ) : (
            <Div>
              {room?.totalTime ? (
                <Wrapper c_text positionRelative>
                  <Div mcontainer>
                    <Content
                      alignCenter
                      dflexColumn
                      style={{ display: "block" }}
                    >
                      <Div dflexColumn alignCenter justifyCenter>
                        <H1 style={{ marginBottom: "8px" }}>
                          <Icon form name="VideoChat" />
                          Meet &amp; Greet Has Ended
                        </H1>
                        <H4>
                          Check out the video and photo(s) of your call with{" "}
                          {event?.artist}!
                        </H4>
                      </Div>
                      <CloudTracker eventId={sessionId} />
                    </Content>
                    <BackgroundImage />
                  </Div>
                </Wrapper>
              ) : (
                <>
                  <Wrapper c_text positionRelative>
                    <Div mcontainer>
                      <Content alignCenter dflexColumn>
                        <H1>This Session Has Ended.</H1>
                        <H3 style={{ marginBottom: "8px" }}>
                          {formattedTime.toFormat("MMMM dd, yyyy (cccc)")}
                        </H3>
                        <H3>
                          {formattedTime.toFormat("h:mm")}
                          {formattedTime.toFormat("a").toLowerCase()} to{" "}
                          {endTime.toFormat("h:mm")}
                          {endTime.toFormat("a").toLowerCase()}{" "}
                          {endTime.toFormat("ZZZZ")}
                        </H3>
                      </Content>
                      <BackgroundImage />
                    </Div>
                  </Wrapper>
                  <MobileDivider />
                  <Wrapper c_text positionRelative>
                    <Div mcontainer>
                      <ContentMargins dflexColumn>
                        <H3>Missed Your Session?</H3>
                        <P style={{ marginTop: "4px" }}>
                          Torquem detraxit hosti et quidem se esse vult,
                          summumque malum et, quantum possit, a philosophis
                          compluribus permulta dicantur, cur nec me tamen
                          laudandis maioribus meis.
                        </P>
                      </ContentMargins>
                    </Div>
                  </Wrapper>
                  <MobileDivider />
                  <Wrapper c_text positionRelative>
                    <Div mcontainer>
                      <ContentMargins>
                        <H3>Booking Details</H3>
                        <Subheader>Participant Information</Subheader>
                        <P>{transaction?.intent?.metadata?.recipientName}</P>
                        <P>{transaction?.intent?.metadata?.recipientEmail}</P>
                        <Subheader>Meet &amp; Greet Information</Subheader>
                        <P>
                          {DateTime.fromJSDate(
                            session?.startDate?.toDate()
                          ).toFormat("DD (cccc)")}
                        </P>
                        <P>
                          {DateTime.fromJSDate(
                            session?.startDate?.toDate()
                          ).toFormat("h a ZZZZ")}
                        </P>
                      </ContentMargins>
                    </Div>
                  </Wrapper>
                </>
              )}
            </Div>
          )}
          <Div w100>{viewShoutoutDesktopUI}</Div>
        </ContentColumns>
      </AppContainer>
      <Footer />
    </AppLayout>
  );
};

const PhoneFileContainer = styled(Div)`
  & > div {
    flex-grow: 1;
  }
  button {
    margin-left: 24px;
  }
`;

const CreditCard = styled(P)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;
    width: 24px;
    height: 15px;
  }
`;

const ShoppingIcon = styled(IconShopping)`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  color: #222222;
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
`;

const BackgroundImage = styled(IconVideoChat)`
  color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  top: -100px;
  left: -30px;
  height: 490px;
  width: 460px;
  opacity: 0.12;
  transform: rotate(-40deg);
  z-index: 0;
`;

const HR = styled.div`
  height: 2px;
  width: 160px;
  border-radius: 1px;
  background-color: #596266;
`;

const WarningMessage = styled(Div)`
  margin-top: 32px;
  border-top: solid 1px ${(props) => props.theme.colors.yellow20};
  border-bottom: solid 1px ${(props) => props.theme.colors.yellow20};
  background: ${(props) => props.theme.colors.yellow10};
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  padding: 16px 60px;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.linkDefault};
    text-decoration: underline;
    user-select: none;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    border-top: none;
    font-size: 15px;
    padding: 20px 5%;
  }
`;

const SocialContainer = styled(Div)`
  svg {
    margin-left: 24px;
    width: 24px;
    height: 24px;
    &:last-of-type {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const SidebarWrapper = styled(Div)`
  border-top: 4px solid #e6e9eb;
  padding-top: 40px;
  margin-top: 40px;
  ${H4} {
    font-weight: 700;
    margin: 0;
  }
  ul {
    list-style: disc;
    padding-left: 28px;
    li {
      color: ${(props) => props.theme.palette.gray.primary};
      font-family: "Overpass", sans-serif;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
    ul li {
      font-size: 15px;
      line-height: 19px;
      & > a {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
`;

import styled, { css } from "styled-components";
import Div from "components/Div";
import { MobileDivider, Section, sectionPadding } from "components/common";
import {
  ButtonPrimary,
  Subheader,
  Link,
  P,
  H1,
  Icon,
  H3,
  H4,
  Small,
  Logo,
} from "notes";
import { useHistory } from "react-router";
import { useState } from "react";
import { useContext } from "react";
import { DateTime, Duration } from "luxon";
import { TimeContext } from "components/TimeProvider";
import { ReactComponent as IconVideoChat } from "assets/IconVideoChat.svg";
import { ReactComponent as IconShopping } from "assets/IconShopping.svg";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import Header, {
  HeaderTextContainer,
  HeaderText,
  ContentColumns,
} from "components/common/Header";
import { AppContainer, AppLayout } from "components/common/layout";
import Footer from "./common/Footer";
import { useMediaQuery } from "hooks";
import { theme } from "theme";
import { UserContext } from "./UserProvider";
import { FormMessage } from "notes";
import * as yup from "yup";
import { Form as FForm, Formik } from "formik";
import { Input } from "./common/formikInputs";
import { useEffect } from "react";
import { shoutoutI } from "firebase-tools";
import { getDomain } from "./utils";
import Spinner from "./common/Spinner";

const HeaderContainer = styled(HeaderTextContainer)`
  margin-bottom: 100px;
  margin-left: 60px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    margin-bottom: 50px;
  }
`;

const Content = styled(Div)`
  padding: 32px 52px 40px;
  position: relative;
  z-index: 1;
  ${H1} {
    display: flex;
    align-items: center;
    font-family: "Overpass";
    font-size: 32px;
    font-weight: 800;
    text-transform: none;
    margin: 0 0 24px 0;
    svg {
      margin-right: 16px;
      width: 40px;
      height: 40px;
      path {
        fill: #222222;
      }
    }
  }
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 12px;
  }
  & > ${H3} {
    display: flex;
    font-weight: 800;
    margin: 0;
  }
  & > div > ${H4} {
    font-weight: 700;
    margin: 0;
    text-align: center;
  }
  ${Subheader} {
    font-size: 17px;
    line-height: 22px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
  }
  ${P} {
    span {
      font-weight: 600;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 24px 0;
    ${H1} {
      font-size: 25px;
      line-height: 32px;
      svg {
        width: 22px;
        height: 22px;
      }
    }
    & > div > ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
    & > ${H3} {
      font-size: 20px;
      line-height: 26px;
      & > svg {
        display: none;
      }
    }
    ${Subheader} {
      font-size: 15px;
      line-height: 19px;
      margin-top: 16px;
    }
  }
`;

const ContentMargins = styled(Content)`
  p + p {
    margin-top: ${(props) => (props.large ? "12px" : "4px")};
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    p {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const Wrapper = styled(Section)`
  padding: 0 !important;
  border: none;
  margin-top: 24px;
  overflow: hidden;
  ${(props) =>
    props.isRequestRejected &&
    css`
      margin-top: -100px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0;
  }
`;

const Button = styled(ButtonPrimary)`
  justify-content: space-between;
  margin-top: 40px;
  padding: 9px 24px 9px 16px;
  width: 392px;
  height: 56px;
  svg {
    width: 21px;
    height: 21px;
    path {
      fill: #ffffff;
    }
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
    }
  }
  &:disabled {
    border: 2px solid ${(props) => props.theme.palette.gray.lighter};
    svg path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const WarningIcon = styled(Div)`
  color: ${(props) => props.theme.colors.orange40};
`;

export const OrderDetailLookup = ({
  match: {
    params: { eventId, sessionId },
  },
  location: { search },
}) => {
  const history = useHistory();
  const { time } = useContext(TimeContext);
  const { user } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.media.mobile);

  const [event, l1]: any = useDocumentData(
    firebase.firestore().doc(`/meet_greet_events/${eventId}`)
  );
  const [session, l2]: any = useDocumentData(
    firebase.firestore().doc(`/meet_greet/${sessionId}`)
  );

  const [transaction, l3, e]: any = useCollectionData(
    firebase
      .firestore()
      .collection(`/meet_greet/${sessionId}/slots`)
      .where("recipientId", "==", user?.uid),
    { idField: "id" }
  );

  const [purchaseTransaction, l4, e2]: any = useCollectionData(
    firebase
      .firestore()
      .collection(`/meet_greet/${sessionId}/slots`)
      .where("requestorId", "==", user?.uid),
    { idField: "id" }
  );

  const expired = search.includes("error");

  const queryParams = new URLSearchParams(search);

  if (l1 || l2 || l3 || l4) {
    return null;
  }

  if (transaction.length && purchaseTransaction.length) {
    history.push(`/orders`);
  }

  if (transaction.length) {
    history.push(`/orders/${eventId}/${sessionId}/${transaction[0].id}`);
  }

  if (purchaseTransaction.length) {
    history.push(
      `/orders/${eventId}/${sessionId}/${purchaseTransaction[0].id}`
    );
  }

  const viewShoutoutDesktopUI = (
    <>
      <SocialContainer mt_32 dflex justifyEnd alignCenter>
        <Subheader>Share:</Subheader>
        <Logo name="FacebookDefault" />
        <Logo name="TwitterDefault" />
        <Icon tertiary name="Link" />
      </SocialContainer>
      <SidebarWrapper>
        <H4>What to Expect</H4>
        <ul>
          <li>
            At your scheduled time slot, you will login and be queued up to meet
            the artist. Typically you can login up to 10 minutes before the meet
            & greet starts.
          </li>
          {event?.checkoutWhatToExpect?.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
          <li>
            Please review the{" "}
            <Link style={{ display: "inline" }}>
              minimum technical requirements
            </Link>{" "}
            and check your audio and video ahead of time. Typically a laptop
            connected to a broadband Wi-Fi is recommended.
          </li>
          <li>
            Please be on time - the artist has allotted a certain amount of time
            to meet fans. If you cannot make your scheduled time slot or cannot
            get your audio and video to work, there will not be a refund.
          </li>
        </ul>
      </SidebarWrapper>
    </>
  );

  const endTime = DateTime.fromJSDate(session?.endDate?.toDate());
  const formattedTime = DateTime.fromJSDate(session?.startDate?.toDate());
  const timeToStart =
    formattedTime &&
    session &&
    Duration.fromMillis((formattedTime as any) - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  return (
    <AppLayout>
      <Header
        backgroundImageUrl={
          isMobile
            ? event?.assets?.squareBanner?.path
            : event?.assets?.preshowBanner?.path
        }
      >
        <HeaderContainer>
          <HeaderText text={event?.artist} />
        </HeaderContainer>
      </Header>
      <AppContainer>
        <ContentColumns>
          <Div>
            <Wrapper c_text positionRelative>
              <Div mcontainer>
                <Content alignCenter dflexColumn>
                  <Div alignCenter>
                    <H1>
                      <Icon form name="VideoChat" />
                      {expired
                        ? "Get Your Login Link!"
                        : "Already Booked Your Meet & Greet?"}
                    </H1>
                  </Div>
                  {expired
                    ? `It looks like you have not previously logged in on this device or in this browser or your session has expired.
                    Click the "Get Link" button, and we'll email you a one time login link to the email address below.
                    Click the link in the email, and you'll be logged in to the Meet & Greet.`
                    : `Type your email below, and we'll email you a one time use link
                  that will gain you admission to the Meet & Greet with ${event?.artist}.
                  Your email will need to match the one that was used to
                  purchase your ticket (or the "participant" email entered by
                  the person who paid for your ticket).`}
                  <OrderLookup
                    eventId={eventId}
                    sessionId={sessionId}
                    email={queryParams.get("email")}
                  />
                </Content>
                <BackgroundImage />
              </Div>
            </Wrapper>
          </Div>
          <Div w100>{viewShoutoutDesktopUI}</Div>
        </ContentColumns>
      </AppContainer>
      <Footer />
    </AppLayout>
  );
};

const PhoneFileContainer = styled(Div)`
  & > div {
    flex-grow: 1;
  }
  button {
    margin-left: 24px;
  }
`;

const CreditCard = styled(P)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;
    width: 24px;
    height: 15px;
  }
`;

const ShoppingIcon = styled(IconShopping)`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  color: #222222;
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
`;

const BackgroundImage = styled(IconVideoChat)`
  color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  top: -100px;
  left: -30px;
  height: 490px;
  width: 460px;
  opacity: 0.12;
  transform: rotate(-40deg);
  z-index: 0;
`;

const HR = styled.div`
  height: 2px;
  width: 160px;
  border-radius: 1px;
  background-color: #596266;
`;

const WarningMessage = styled(Div)`
  margin-top: 32px;
  border-top: solid 1px ${(props) => props.theme.colors.yellow20};
  border-bottom: solid 1px ${(props) => props.theme.colors.yellow20};
  background: ${(props) => props.theme.colors.yellow10};
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  padding: 16px 60px;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.linkDefault};
    text-decoration: underline;
    user-select: none;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    border-top: none;
    font-size: 15px;
    padding: 20px 5%;
  }
`;

const SocialContainer = styled(Div)`
  svg {
    margin-left: 24px;
    width: 24px;
    height: 24px;
    &:last-of-type {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const SidebarWrapper = styled(Div)`
  border-top: 4px solid #e6e9eb;
  padding-top: 40px;
  margin-top: 40px;
  ${H4} {
    font-weight: 700;
    margin: 0;
  }
  ul {
    list-style: disc;
    padding-left: 28px;
    li {
      color: ${(props) => props.theme.palette.gray.primary};
      font-family: "Overpass", sans-serif;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
    ul li {
      font-size: 15px;
      line-height: 19px;
      & > a {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email("This does not appear to be a valid email address.")
    .required("This is a required field and cannot be blank."),
});

const SubmitButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: 7px;
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

const Info = styled(FormMessage)`
  padding: 0 20px;
  margin: 24px 0;
`;

const SuccessMessage = styled.span`
  span {
    color: ${(props) => props.theme.colors.linkDefault};
    user-select: none;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 2px;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const OrderLookupText = styled(Div)`
  font-size: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
  }
`;

const DescriptionText = styled(Div)`
  font-size: 17px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

const OrderLookup = ({ eventId, sessionId, email = "" }) => {
  const initialValues = { email };
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isInvalidAccount, setIsInvalidAccount] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async () => {
    setIsLoading(true);
    const res = await shoutoutI.functions().auth.sendLoginEmail({
      email: submittedEmail,
      url: `${getDomain()}/orders/${eventId}/${sessionId}`,
    });
    setIsLoading(false);
    if (!res.error) setIsLinkSent(true);
    else setIsInvalidAccount(true);
  };

  useEffect(() => {
    setIsInvalidAccount(false);
    setIsLinkSent(false);
    if (submittedEmail) sendEmail();
  }, [submittedEmail]);

  const handleSubmit = (values) => {
    setSubmittedEmail(values.email);
  };

  const warningUI = isInvalidAccount && (
    <WarningMessage>No account found with that email address.</WarningMessage>
  );

  const linkSentUI = isLinkSent && (
    <Info
      active
      content={
        <SuccessMessage>
          A link to your order history has been sent to {submittedEmail}. Didn’t
          get it? <span onClick={sendEmail}>Send Again</span>
        </SuccessMessage>
      }
      type="info"
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, values }) => (
        <FForm>
          <Div mb_30>
            <Input
              name="email"
              placeholder="john@email.com"
              leftIcon={<Icon form name="Email" />}
            />
          </Div>

          {values.email === submittedEmail && (
            <>
              {linkSentUI} {warningUI}
            </>
          )}
          <SubmitButton
            isLoading={isLoading}
            onClick={submitForm}
            style={{ maxWidth: "392px" }}
          >
            {isLoading ? (
              <Spinner color="white" size="10px" />
            ) : (
              "Locate Order History"
            )}
          </SubmitButton>
        </FForm>
      )}
    </Formik>
  );
};

export default OrderLookup;

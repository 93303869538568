import Div from "components/Div";
import Header, {
  HeaderTextContainer,
  HeaderText,
  ContentColumns,
} from "components/common/Header";
import { AppContainer, AppLayout } from "components/common/layout";
import styled from "styled-components";
import { Subheader, Logo, Link, H4, Icon } from "notes";
import Footer from "components/common/Footer";
import { useEffect, createContext } from "react";
import { theme } from "theme";
import Summary from "./Summary";
import { useHistory } from "react-router";
import { useMediaQuery } from "hooks";
import { MobileDivider } from "components/common";
import PaymentForm from "components/ShoutoutOrder/PaymentForm";
import { useState } from "react";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { any } from "prop-types";
import { useContext } from "react";
import { TimeContext } from "components/TimeProvider";
import { DateTime, Duration } from "luxon";
import Confirmation from "./Confirmation";
import { formatCurrency } from "components/formatCurrency";

export const CheckoutContext = createContext(undefined);
export const CheckoutConsumer = CheckoutContext.Consumer;
export const CheckoutProvider = ({ eventId, children }) => {
  const [formData, setFormData]: any = useState({
    name: "",
    firstName: "",
    friend: false,
    email: "",
    recipientEmail: "",
    phone: "",
    consent: true,
    timeslot: null,
  });
  const [friend, setFriend] = useState(false);
  const [consent, setConsent] = useState(true);
  const [reservation, setReservation]: any = useState();
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [event, le, ee]: any = useDocumentData(
    firebase.firestore().doc(`meet_greet_events/${eventId}`),
    { idField: "id" }
  );
  const [slots, ls, es]: any = useCollectionData(
    firebase
      .firestore()
      .collectionGroup("slots")
      .where("reservedAt", "==", null)
      .where("eventId", "==", eventId)
  );
  const [times, lt, et] = useCollectionData(
    firebase
      .firestore()
      .collection("meet_greet")
      .where("eventId", "==", eventId)
      .orderBy("startDate"),
    { idField: "id" }
  );
  const reset = () => {
    if (reservation) {
      firebase
        .firestore()
        .doc(`meet_greet/${formData.timeslot}/slots/${reservation.id}`)
        .update({
          reservedAt: null,
          uid: null,
        });
    }
    setReservation(undefined);
    setComplete(false);
  };
  const counts = slots?.reduce((acc, s) => {
    acc[s.sessionId] = (acc[s.sessionId] || 0) + 1;
    return acc;
  }, {});
  const timeslots = times?.map((t: any) => {
    return {
      ...t,
      slotsAvailable: counts[t.id],
    };
  });

  const reserveSlot = async (formData: any) => {
    setFormData(formData);
    const reservation = await firebase
      .functions()
      .httpsCallable("meetgreet-reserveSlot")({
      sessionId: formData.timeslot,
      email: formData.email,
    });
    setReservation(reservation?.data);
    return reservation;
  };

  if (le || ls || lt) {
    return null;
  }
  return (
    <CheckoutContext.Provider
      value={{
        eventId,
        event,
        timeslots,
        reservation,
        setReservation,
        name: formData.name,
        friend,
        setFriend,
        email: formData.email,
        phone: formData.phone,
        consent,
        setConsent,
        timeslot: formData.timeslot,
        price: event?.unitPrice,
        fee: event?.processingFeeAmount,
        amount: event?.unitPrice + event?.processingFeeAmount,
        complete,
        setComplete,
        reset,
        loading,
        setLoading,
        firstName: formData.firstName,
        lastName: "",
        recipientEmail: formData.recipientEmail,
        reserveSlot,
        formData,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const Checkout = ({
  match: {
    params: { eventId },
  },
}) => {
  return (
    <CheckoutProvider eventId={eventId}>
      <CheckoutInternal />
    </CheckoutProvider>
  );
};

const CheckoutInternal = () => {
  const isMobile = useMediaQuery(theme.media.mobile);
  const { event, reservation, complete, reset, loading, price } =
    useContext(CheckoutContext);
  const { time } = useContext(TimeContext);
  // const history = useHistory();
  const [reservedSlot, load, err]: any = useDocumentData(
    firebase
      .firestore()
      .doc(`meet_greet/${reservation?.sessionId}/slots/${reservation?.id}`)
  );

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = theme.colors.white;
    else document.body.style.backgroundColor = theme.colors.background;
  }, [isMobile]);

  const formattedTime = DateTime.fromJSDate(
    reservedSlot?.reservedAt?.toDate()
  ).plus({
    minutes: 5,
  });

  useEffect(() => {
    if (formattedTime < time && !loading && reservation && !complete) {
      reset();
    }
  }, [time, loading]);

  const timeRemaining =
    reservedSlot &&
    Duration.fromMillis((formattedTime as any) - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const headerUI = (
    <HeaderTextContainer>
      <HeaderText
        text={event.artist}
        price={!complete && formatCurrency(price)}
      />
    </HeaderTextContainer>
  );

  const viewShoutoutDesktopUI = (
    <>
      <SocialContainer mt_32 dflex justifyEnd alignCenter>
        <Subheader>Share:</Subheader>
        <Logo name="FacebookDefault" />
        <Logo name="TwitterDefault" />
        <Icon tertiary name="Link" />
      </SocialContainer>
      <SidebarWrapper>
        <H4>What to Expect</H4>
        <ul>
          <li>
            At your scheduled time slot, you will login and be queued up to meet
            the artist. Typically you can login up to 10 minutes before the meet
            & greet starts.
          </li>
          {event?.checkoutWhatToExpect?.map((c, index) => (
            <li key={index}>{c}</li>
          ))}
          <li>
            Please review the{" "}
            <Link style={{ fontSize: "17px", display: "inline" }}>
              minimum technical requirements
            </Link>{" "}
            and check your audio and video ahead of time. Typically a laptop
            connected to a broadband Wi-Fi is recommended.
          </li>
          <li>
            Please be on time - the artist has allotted a certain amount of time
            to meet fans. If you cannot make your scheduled time slot or cannot
            get your audio and video to work, there will not be a refund.
          </li>
        </ul>
      </SidebarWrapper>
    </>
  );

  return (
    <AppLayout>
      <Header
        withLogo
        backgroundImageUrl={
          isMobile
            ? event?.assets?.squareBanner?.path
            : event?.assets?.preshowBanner?.path
        }
      >
        {headerUI}
      </Header>
      <AppContainer>
        {complete ? (
          <StyledContentColumn>
            <PullUpContainer>
              <Confirmation />
            </PullUpContainer>
            <Div w100>{viewShoutoutDesktopUI}</Div>
          </StyledContentColumn>
        ) : reservedSlot ? (
          <PaymentForm timeRemaining={timeRemaining} />
        ) : (
          <StyledContentColumn>
            <PullUpContainer>
              <Summary />
            </PullUpContainer>
            <Div w100>{viewShoutoutDesktopUI}</Div>
          </StyledContentColumn>
        )}
      </AppContainer>
      {!reservedSlot && (
        <Footer
          background={isMobile ? theme.colors.white : theme.colors.background}
        />
      )}
    </AppLayout>
  );
};

const StyledContentColumn = styled(ContentColumns)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    margin-bottom: 0;
  }
`;

const PullUpContainer = styled(Div)`
  position: relative;
  top: -100px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    top: initial;
  }
`;

const SocialContainer = styled(Div)`
  svg {
    margin-left: 24px;
    width: 24px;
    height: 24px;
    &:last-of-type {
      path {
        fill: ${(props) => props.theme.palette.black};
      }
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const SidebarWrapper = styled(Div)`
  border-top: 4px solid #e6e9eb;
  padding-top: 40px;
  margin-top: 40px;
  ${H4} {
    font-weight: 700;
    margin: 0;
  }
  ul {
    list-style: disc;
    padding-left: 28px;
    li {
      color: ${(props) => props.theme.palette.gray.primary};
      font-family: "Overpass", sans-serif;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    border-top: none;
    padding: 24px;
    margin-top: 0;
    margin-bottom: 5px;
    ${H4} {
      font-size: 15px;
      line-height: 19px;
    }
    ul {
      li {
        font-size: 15px;
        line-height: 19px;
      }
    }
    &:before {
      background-color: #fafafa;
      box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.24);
      content: "";
      display: block;
      margin-top: -24px;
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      z-index: 3;
    }
  }
`;

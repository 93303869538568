import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/storage";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import * as shoutoutI from "@musicaudienceexchange/shoutout-interface";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

shoutoutI.initializeApp({ firebase });
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage();

export const fb = firebase;
export const fstore = firebase.firestore();
export const useFBDoc = (path) => useDocumentData(fstore.doc(path));
export const useFBCollection = (path) => useCollection(fstore.collection(path));
export { shoutoutI };

export default firebase;

import Div from "components/Div";
import styled from "styled-components";
import { Section } from ".";
import { ShopIcon } from "components/common/icons";
import { usePaymentContext } from "components/PaymentContextProvider";
import { getDisplayPrice } from "components/utils";
import { formatCurrency } from "components/formatCurrency";
import { useContext } from "react";
import { CheckoutContext } from "components/ShoutoutRequest";

const HeaderText = styled(Div)`
  padding: 26px 50px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 700;
  font-size: 25px;
`;

const OrderTotal = styled(Div)`
  padding: 23px 50px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 16px 0;
  }
`;

const OrderTotalValue = styled(Div)`
  font-size: 25px;
  margin-bottom: -4px;

  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    margin-bottom: -1px;
  }
`;

const Divider = styled(Div)`
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  height: 1px;
`;

const PaymentHeader = () => {
  const { amount } = useContext(CheckoutContext);
  return (
    <Section mcontainer>
      <HeaderText forDesktop> Payment </HeaderText>
      <Divider forDesktop />
      <OrderTotal spaceBetween alignItemsEnd>
        <Div dflex alignCenter mb_n3>
          <Div mt_5>
            <ShopIcon width="22px" height="22px" />
          </Div>
          <Div ml_6 fs_17 bold>
            Order Total
          </Div>
        </Div>
        <Div dflex alignItemsEnd>
          <Div fs_14 mr_6 bold>
            USD
          </Div>
          <OrderTotalValue style={{ fontWeight: 800 }}>
            {formatCurrency(amount)}
          </OrderTotalValue>
        </Div>
      </OrderTotal>
    </Section>
  );
};

export default PaymentHeader;
